/**
 * 自定义Action Creator by lanbo 2017-08-01
 *
 * 1.2017-08-01  rlair_添加自定义用户搜索
 * 2.2017-08-02  rlair_自动完成搜索用户 by lanbo
 * 3.2017-09-04  rlair_添加修改频道last_post_at的action
 *
 */

import {batchActions} from 'redux-batched-actions';
import {Client4} from 'client';
import {UserTypes, CustomizeTypes} from 'action_types';
import {removeUserFromList} from 'utils/user_utils';

import {forceLogoutIfNecessary} from './helpers';
import {logError} from './errors';

// 用户搜索
export function customsizeSearchProfiles(term, options = {}, successCallback, errorCallback) {
    return (dispatch, getState) => {
        dispatch({type: UserTypes.SEARCH_PROFILES_REQUEST}, getState);

        const {currentUserId} = getState().entities.users;

        let profiles;

        return Client4.customsizeSearchUsers(term, options,
            (data) => {
                profiles = data;
                const actions = [{type: UserTypes.RECEIVED_PROFILES_LIST, data: removeUserFromList(currentUserId, [...profiles])}];
                if (options.in_channel_id) {
                    actions.push({
                        type: UserTypes.RECEIVED_PROFILES_LIST_IN_CHANNEL,
                        data: profiles,
                        id: options.in_channel_id,
                    });
                }

                if (options.not_in_channel_id) {
                    actions.push({
                        type: UserTypes.RECEIVED_PROFILES_LIST_NOT_IN_CHANNEL,
                        data: profiles,
                        id: options.not_in_channel_id,
                    });
                }

                if (options.team_id) {
                    actions.push({
                        type: UserTypes.RECEIVED_PROFILES_LIST_IN_TEAM,
                        data: profiles,
                        id: options.team_id,
                    });
                }

                if (options.not_in_team_id) {
                    actions.push({
                        type: UserTypes.RECEIVED_PROFILES_LIST_NOT_IN_TEAM,
                        data: profiles,
                        id: options.not_in_team_id,
                    });
                }

                dispatch(batchActions([
                    ...actions,
                    {
                        type: UserTypes.SEARCH_PROFILES_SUCCESS,
                    },
                ]), getState);

                if (successCallback) {
                    successCallback(data);
                }
            },
            (error) => {
                forceLogoutIfNecessary(error, dispatch, getState);
                dispatch(batchActions([
                    {type: UserTypes.SEARCH_PROFILES_FAILURE, error},
                    logError(error)(dispatch),
                ]), getState);

                if (errorCallback) {
                    errorCallback(error);
                }
            }
        );
    };
}

// 自动完成搜索用户 by lanbo
export function customsizeAutocompleteUsers(term, teamId = '', channelId = '', successCallback, errorCallback) {
    return (dispatch, getState) => {
        dispatch({type: UserTypes.AUTOCOMPLETE_USERS_REQUEST}, getState);

        const {currentUserId} = getState().entities.users;

        let data;

        return Client4.customsizeAutocompleteUsers(term, teamId, channelId,
            (res) => {
                data = res;
                let users = [...data.users];
                if (data.out_of_channel) {
                    users = [...users, ...data.out_of_channel];
                }
                removeUserFromList(currentUserId, users);

                const actions = [
                    {
                        type: UserTypes.RECEIVED_PROFILES_LIST,
                        data: users,
                    },
                    {
                        type: UserTypes.AUTOCOMPLETE_USERS_SUCCESS,
                    },
                ];

                if (channelId) {
                    actions.push(
                        {
                            type: UserTypes.RECEIVED_PROFILES_LIST_IN_CHANNEL,
                            data: data.users,
                            id: channelId,
                        }
                    );
                    actions.push(
                        {
                            type: UserTypes.RECEIVED_PROFILES_LIST_NOT_IN_CHANNEL,
                            data: data.out_of_channel,
                            id: channelId,
                        }
                    );
                }

                if (teamId) {
                    actions.push(
                        {
                            type: UserTypes.RECEIVED_PROFILES_LIST_IN_TEAM,
                            data: users,
                            id: teamId,
                        }
                    );
                }

                dispatch(batchActions(actions), getState);

                if (successCallback) {
                    successCallback(data);
                }
            },
            (error) => {
                forceLogoutIfNecessary(error, dispatch);
                dispatch(batchActions([
                    {type: UserTypes.AUTOCOMPLETE_USERS_FAILURE, error},
                    logError(error)(dispatch),
                ]), getState);

                if (errorCallback) {
                    errorCallback(error);
                }
            }
        );
    };
}

export function customsizeGetChannel(channelId) {
    return async (dispatch, getState) => {
        let data;
        try {
            data = await Client4.getChannel(channelId);
        } catch (error) {
            return null;
        }

        dispatch({
            type: CustomizeTypes.CUSTOMIZE_CHANNELS_LAST_POST_AT,
            data,
        }, getState);

        return data;
    };
}
