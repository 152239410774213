/**
 * 客户端http请求
 *
 * 1.2016-11-11  rlair_上传文件函数增加参数：文件MD5码
 * 2.2016-11-28  rlair_根据规则，增加注释
 * 3.2016-12-06  rlair_增加组织机构查询方法
 * 4.2016-12-08  rlair_增加获取组织机构树下人员列表接口
 * 5.2016-12-30  rlair_增加设置/取消设置频道成员为频道管理员
 * 6.2017-07-28  rlair_转发中的搜索用户请求用request封装
 * 7.2017-08-02  rlair_自动完成搜索用户 by lanbo
 * 8.2017-08-09  rlair_发送消息增加team_id
 * 9.2017-08-02  rlair_office文件预览查询文件预览地址接口
 * 10.2017-08-17  rlair_去除发送消息增加team_id
 * 11.2017-08-21 rlair_增加获取oa跳转所需jwt的token接口
 * 12.2018-04-08  rlair_增加在线客服旅客聊天界面申请在线客服（会话连接）接口
 * 13.2018-04-09  rlair_增加获取在线客服系统配置信息（含公告内容）接口
 * 14.2018-04-09  rlair_增加在线客服旅客评价提交接口
 * 15.2018-04-09  rlair_增加在线客服判断是否有客服接口
 * 16.2018-06-11  rlair_修改在线客服配置获取接口地址
 * 17.2018-06-11  rlair_修改在线客服分配客服接口地址
 * 18.2018-06-11  rlair_修改在线客服提交评价接口地址
 * 19.2018-06-11  rlair_修改在线客服判断是否有客服在线接口地址
 * 20.2018-06-22  rlair_支持下发跳转地址
 * 21.2018-07-06  rlair_增加获取定位信息图片及url地址的接口
 * 22.2018-06-11 rlair_新增旅客消息发送接口
 */

import request from 'superagent';

const HEADER_X_VERSION_ID = 'x-version-id';
const HEADER_X_CLUSTER_ID = 'x-cluster-id';

export default class customsizeClient {
    // 得到组织机构api地址
    getOrgRoute() {
        return `${this.url}${this.urlVersion}/organizations`;
    }

    // 版本更新保存地址配置
    getVersionRoute() {
        return `${this.url}${this.urlVersion}/version`;
    }

    // 获取组织机构树
    getOrgTree() {
        return this.doFetch(
            `${this.getOrgRoute()}/getOrgTreeNodes`,
            {method: 'get'}
        );
    }

    // 获取组织机构树下人员列表
    getOrgTreeUsers(orgId) {
        return this.doFetch(
            `${this.getOrgRoute()}/${orgId}/users/0/10000`,
            {method: 'get'}
        );
    }

    // 保存版本更新信息请求配置
    addVersion(version) {
        return this.doFetch(
            `${this.getVersionRoute()}/add`,
            {method: 'post', body: JSON.stringify(version)}
        );
    }

    // 取得版本更新数据列表请求配置
    getVersion(page) {
        return this.doFetch(
            `${this.getVersionRoute()}/versions?${page}`,
            {method: 'get'}
        );
    }

    // 删除版本更新信息请求配置
    delVersion(version) {
        return this.doFetch(
            `${this.getVersionRoute()}/add`,
            {method: 'post', body: JSON.stringify(version)}
        );
    }

    // 多人消息群发请求
    createPostBroadcast(post) {
        this.trackEvent('api', 'api_posts_create_broadcast', post.channel_ids[0], post.source_id ? 'source_id' : 'length', post.source_id || post.message.length);

        return this.doFetch(
            `${this.getPostsRoute()}/broadcast`,
            {method: 'post', body: JSON.stringify({...post, create_at: 0})}
        );
    }

    // office文件预览查询文件预览地址
    getOfficePreviewUrl(fileId) {
        return this.doFetch(
            `${this.url}${this.urlVersion}/preview/${fileId}/get`,
            {method: 'get'}
        );
    }

    // Add a api which for message-transpond to get post by id, rlair_luyu 2017/8/23
    // 添加一个用于信息转发的 API，以获取 post 信息
    getPostThreadOriginal = async (postId) => {
        return this.doFetch(
            `${this.getPostRoute(postId)}/thread/original`,
            {method: 'get'}
        );
    }

    // 获取jwt所需token
    getJwtToken() {
        return this.doFetch(
            `${this.url}${this.urlVersion}/jwt/token`,
            {method: 'get'}
        );
    }

    // 获取文件是否存在服务端地址
    customsizeGetFileExistRoute() {
        return `${this.getFilesRoute()}/isfileexist`;
    }

    // 通过上传MD5码，判断文件是否存在
    isFileExisted(filename, channelId, clientId, md5, success, error) {
        return request.
            post(this.customsizeGetFileExistRoute()).
            set({'X-Requested-With': 'XMLHttpRequest'}).
            type('application/json').
            accept('application/json').
            send({filename, md5, channel_id: channelId, client_id: clientId}).
            end(this.handleResponse.bind(this, 'isFileExisted', success, error));
    }

    // 仅上传文件MD5码，不包含文件
    // uploadFileWithoutFile(channelId, clientId, md5, success, error) {
    //     return request.
    //         post(`${this.getFilesRoute()}/upload`).
    //         set({'X-Requested-With': 'XMLHttpRequest'}).
    //         field('channel_id', channelId).
    //         field('client_ids', clientId).
    //         field('md5', md5).
    //         accept('application/json').
    //         end(this.handleResponse.bind(this, 'uploadFileWithoutFile', success, error));
    // }

    // 上传文件
    customsizeUploadFile(file, filename, channelId, clientId, md5, success, error) {
        return request.
            post(`${this.getFilesRoute()}`).
            set({'X-Requested-With': 'XMLHttpRequest'}).
            attach('files', file, filename).
            field('channel_id', channelId).
            field('client_ids', clientId).
            field('md5', md5).
            accept('application/json').
            end(this.handleResponse.bind(this, 'customsizeUploadFile', success, error));
    }

    // 自定义请求响应函数上传文件 5.0
    customizeUploadFileWithHandleResponse(file, filename, channelId, clientId, md5, handleResponse) {
        return request.
            post(`${this.getFilesRoute()}`).
            set(this.getOptions().headers).
            attach('files', file, filename).
            field('channel_id', channelId).
            field('client_ids', clientId).
            field('md5', md5).
            accept('application/json').
            end(handleResponse);
    }

    // 获取定位信息图片
    customizeGetLocationPicUrl({lon, lat, key}) {
        return `https://restapi.amap.com/v3/staticmap?location=${lon},${lat}&zoom=9&size=400*300&markers=mid,,:${lon},${lat}&key=${key}`;
    }

    // 获取定位信息地址
    customizeGetLocationUrl({lon, lat, address, key}) {
        return `https://m.amap.com/navi/?dest=${lon},${lat}&destName=${encodeURIComponent(address)}&hideRouteIcon=1&key=${key}`;
    }

    // 用户搜索
    customsizeSearchUsers(term, options, success, error) {
        this.trackEvent('api', 'api_search_users_customsize');
        return request.
            post(`${this.getUsersRoute()}/search`).
            set({'X-Requested-With': 'XMLHttpRequest'}).
            type('application/json').
            accept('application/json').
            send({term, ...options}).
            end(this.handleResponse.bind(this, 'customsizeSearchUsers', success, error));
    }

    // 自动完成搜索用户 by lanbo
    customsizeAutocompleteUsers(name, teamId, channelId, success, error) {
        return request.
            get(`${this.getUsersRoute()}/autocomplete${buildQueryString({in_team: teamId, in_channel: channelId, name})}`).
            set({'X-Requested-With': 'XMLHttpRequest'}).
            type('application/json').
            accept('application/json').
            end(this.handleResponse.bind(this, 'customsizeAutocompleteUsers', success, error));
    }

    // 申请在线客服
    // 2018-06-11  rlair_修改接口地址
    customsizeApplyOnlineSupport(channelId) {
        return this.doFetch(
            `${this.url}${this.urlVersion}/online_support/guests/channels/${channelId}/apply_online_support`,
            {method: 'post'}
        );
    }

    // 获取在线客服系统配置信息（含公告内容）
    // 2018-06-11  rlair_修改在线客服配置获取接口地址
    // 2018-06-22  rlair_支持下发调转地址
    customsizeOnlineSupportConfig(source) {
        return this.doFetch(
            `${this.url}${this.urlVersion}/online_support/guests/config?from=${source}`,
            {method: 'get'}
        );
    }

    // 在线客服旅客评价提交接口
    // 2018-06-11  rlair_修改接口地址
    customsizeOnlineSupportEvaluate(channelId, data) {
        return this.doFetch(
            `${this.url}${this.urlVersion}/online_support/guests/channels/${channelId}/evaluate`,
            {method: 'post', body: JSON.stringify({...data})}
        );
    }

    // 在线客服判断是否有客服接口
    // 2018-06-11  rlair_修改接口地址
    customsizeOnlineSupportStatus(channelId) {
        return this.doFetch(
            `${this.url}${this.urlVersion}/online_support/guests/channels/${channelId}/status`,
            {method: 'get'}
        );
    }

    // 旅客消息发送接口
    customsizeGuestCreatePost = async (post) => {
        this.trackEvent('api', 'api_posts_create', {channel_id: post.channel_id});

        if (post.root_id != null && post.root_id !== '') {
            this.trackEvent('api', 'api_posts_replied', {channel_id: post.channel_id});
        }

        return this.doFetch(
            `${this.getBaseRoute()}/online_support/guests/posts`,
            {method: 'post', body: JSON.stringify(post)}
        );
    };

    handleResponse(methodName, successCallback, errorCallback, err, res) {
        if (res && res.header) {
            if (res.header[HEADER_X_VERSION_ID]) {
                this.serverVersion = res.header[HEADER_X_VERSION_ID];
            }

            if (res.header[HEADER_X_CLUSTER_ID]) {
                this.clusterId = res.header[HEADER_X_CLUSTER_ID];
            }
        }

        if (err) {
            // test to make sure it looks like a server JSON error response
            var e = null;
            if (res && res.body && res.body.id) {
                e = res.body;
            }

            var msg = '';

            if (e) {
                msg = 'method=' + methodName + ' msg=' + e.message + ' detail=' + e.detailed_error + ' rid=' + e.request_id;
            } else {
                msg = 'method=' + methodName + ' status=' + err.status + ' statusCode=' + err.statusCode + ' err=' + err;

                if (err.status === 0 || !err.status) {
                    e = {message: this.translations.connectionError};
                } else {
                    e = {message: this.translations.unknownError + ' (' + err.status + ')'};
                }
            }

            if (this.logToConsole) {
                console.error(msg); // eslint-disable-line no-console
                console.error(e); // eslint-disable-line no-console
            }

            if (errorCallback) {
                errorCallback(e, err, res);
            }
            return;
        }

        if (successCallback) {
            //if (res && res.body !== undefined) { // eslint-disable-line no-undefined
            //修复ios js报错问题
            if (res && res.body) {
                successCallback(res.body, res);
            } else {
                // 修复由于res.body不为undefined时报错的问题
                if (res && res.body === undefined) { // eslint-disable-line no-undefined
                    console.error('Missing response body for ' + methodName); // eslint-disable-line no-console
                }
                successCallback('', res);
            }
        }
    }
}

function buildQueryString(parameters) {
    const keys = Object.keys(parameters);
    if (keys.length === 0) {
        return '';
    }

    let query = '?';
    for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        query += key + '=' + encodeURIComponent(parameters[key]);

        if (i < keys.length - 1) {
            query += '&';
        }
    }

    return query;
}
