/**
 * 自定义Action types by lanbo 2017-09-04
 *
 * 1.2017-09-04  rlair_添加修改频道last_post_at的type
 *
 */

import keyMirror from 'utils/key_mirror';

export default keyMirror({
    CUSTOMIZE_CHANNELS_LAST_POST_AT: null,
});
